import React from 'react';
import { PageWrapper } from '~components/Core';
import FaqSection from '~sections/pricing-2/Faq';
import PricingSection from '~sections/pricing-2/Pricing';
import FooterFour from '~sections/app/FooterFour';
import HeaderButton from '~sections/app/Header';
import { Link } from '~components';

const header = {
  headerClasses:
    'site-header site-header--menu-end light-header site-header--sticky',
  containerFluid: false,
  buttonBlock: (
    <HeaderButton
      as={Link}
      className="d-none d-sm-flex"
      children="App Login"
      target="_blank"
      to="https://app.actovos.com"
    />
  ),
};

export default function PricingTwo() {
  return (
    <PageWrapper headerConfig={header}>
      <PricingSection />
      <FaqSection />
      <FooterFour />
    </PageWrapper>
  );
}
